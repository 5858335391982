import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import innerCover from "assets/images/banners/banner-contact.webp";
import Link from "@mui/material/Link";

function Committees() {
  return (
    <div>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["2xl"],
            },
          })}
        >
          Committees
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: { xs: 2, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={8}>
            <ul className="committee-list">
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/ICC+2024_v2_24_12_24.pdf">
                  ICC{" "}
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/Anti+ragging+Com.pdf">
                  Anti ragging committee{" "}
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/Anti+Ragging+Squad.pdf">
                  Anti ragging squad{" "}
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/SC+ST+Development+Committee.pdf">
                  SC/ST development Committee{" "}
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/Internal+Appeal+Committee.pdf">
                  Internal Appeal Committee{" "}
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/Journal+Committee.pdf">
                  Journal Committee{" "}
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/OBC+Cell.pdf">
                  OBC Cell
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/Students+Grie+Redre+Comm.pdf">
                  Students Grievance Redressal Committee
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link nk target="_blank" href="/file-preview?file=https://snlc-files.s3.ap-south-1.amazonaws.com/committees/Minority+cell.pdf">
                  Minority Cell
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Link>
              </li>

            </ul>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Committees;
